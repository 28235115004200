import { useRef, useCallback, useEffect, useState } from "react";
import { Camera } from "@mediapipe/camera_utils";
import { Pose, POSE_LANDMARKS, POSE_LANDMARKS_LEFT, POSE_LANDMARKS_RIGHT } from "@mediapipe/pose";
import { FaceMesh ,FACEMESH_LEFT_IRIS } from '@mediapipe/face_mesh'
import { Link } from "react-router-dom";
import { findAngle } from "../Utils/PoseAlgorithms";

declare global {
  interface Window {
    stream?: any;
  }
}
function MeasureTool() {
  let pose: any;
  let faceMesh:any;

  const [params, setParsms] = useState({
    POSITION:'UN_KNOWN',
    LEFT_HAND_ANGLE:'',
    RIGHT_HAND_ANGLE:'',
    SHOULDER_WIDTH:'',
    RIGHT_KNEE_ANGLE:"",
    LEFT_KNEE_ANGLE:""
  });

  const [externalUrl, setExternalUrl] = useState('');
  const [cameraDistance, setCameraDistance] = useState('NA');

  const videoElementRef = useRef<any>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const medpipeURL = "https://cdn.jsdelivr.net/npm/@mediapipe/pose";
  const mpFaceMeshURL = "https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh";
  const poseOptions = {
    modelComplexity: 2,
    smoothLandmarks: true,
    enableSegmentation: true,
    smoothSegmentation: true,
    minDetectionConfidence: 0.5,
    minTrackingConfidence: 0.2,
  };
  const solutionOptions = {
    selfieMode: true,
    enableFaceGeometry: false,
    maxNumFaces: 1,
    refineLandmarks: true,
    minDetectionConfidence: 0.5,
    minTrackingConfidence: 0.5
  };
  useEffect(() => {
    if (pose) return;

    pose = new Pose({
      locateFile: (file) => {
        return `${medpipeURL}/${file}`;
      },
    });

    pose.setOptions(poseOptions);
    pose.onResults(resultsCallback);
  }, []);

  useEffect(() => {
    if (faceMesh) return;

    faceMesh = new FaceMesh({
      locateFile: (file) => {
        return `${mpFaceMeshURL}/${file}`;
      },
    });

    faceMesh.setOptions(solutionOptions);
    faceMesh.onResults(faceMeshResultsCallback);
  }, []);

  const faceMeshResultsCallback = useCallback((results: any) => {
    var width = results.image.width;
    var height = results.image.height;
  
    var irisLeftMinX = -1;
    var irisLeftMaxX = -1;

    if (results.multiFaceLandmarks) {
      for (const landmarks of results.multiFaceLandmarks) {
        for (const point of FACEMESH_LEFT_IRIS) {
          var point0 = landmarks[point[0]];
          if (irisLeftMinX == -1 || point0.x * width < irisLeftMinX) {
            irisLeftMinX = point0.x * width;
          }
          if (irisLeftMaxX == -1 || point0.x * width > irisLeftMaxX) {
            irisLeftMaxX = point0.x * width;
          }
        }
      }
    }
  
    var dx = irisLeftMaxX - irisLeftMinX;
    var dX = 11.7;
  
    // Logitech HD Pro C922	Norm focal
    var normalizedFocaleX = 1.40625;
    var fx = Math.min(width, height) * normalizedFocaleX;
    var dZ:any = (fx * (dX / dx)) / 10.0;
    dZ = dZ.toFixed(2);
    if(isFinite(dZ)){
      setCameraDistance(dZ + " cm")
    } else {
      setCameraDistance('Person not infront of camera')
    }
  }, []);

  const resultsCallback = useCallback((results: any) => {
    const landmarks = results.poseLandmarks ?? {};
    if (Object.keys(landmarks).length) {
      drawLandmarksHandler(landmarks);
    }
  }, []);

  useEffect(() => {
    // if (videoElementRef) startPoseModel();

    return () => {
      stopCamera();
    };
  }, [videoElementRef, resultsCallback]);

  const startPoseModel = useCallback(() => {
    if (videoElementRef.current && pose) {
      const camera = new Camera(videoElementRef.current, {
        onFrame: async () => {
          await pose?.send({ image: videoElementRef.current });
          await faceMesh.send({ image:  videoElementRef.current });
        },
        width: 640,
        height: 480,
      });
      camera.start();
    }
  }, [pose]);

  const stopCamera = () => {
    window?.stream?.getTracks()?.forEach((track: any) => track.stop());

    if (videoElementRef.current === null) {
      return;
    }

    const stream = videoElementRef.current.srcObject as MediaStream;
    stream?.getTracks()?.forEach((track) => track.stop());
    videoElementRef.current.srcObject = null;
  };

  const drawLandmarksHandler = (landmarks: any) => {
    // no need to draw anything
    if (!canvasRef || !canvasRef.current) {
      return;
    }

    const canvasEl = canvasRef.current;
    const ctx = canvasEl?.getContext("2d");

    if (canvasEl && ctx) {
      canvasEl.height = canvasEl.clientHeight;
      canvasEl.width = canvasEl.clientWidth;

      ctx.save();
      ctx.clearRect(0, 0, canvasEl.width, canvasEl.height);

      const width = canvasEl.width;
      const height = canvasEl.height;

      // calc x/y
      const l_eye = {
        x: landmarks[2]?.x * width,
        y: landmarks[2]?.y * height,
        key: "l_eye",
      };
      const r_eye = {
        x: landmarks[5]?.x * width,
        y: landmarks[5]?.y * height,
        key: "r_eye",
      };
      const l_mouth = {
        x: landmarks[9]?.x * width,
        y: landmarks[9]?.y * height,
        key: "l_mouth",
      };
      const l_shoulder = {
        x: landmarks[11]?.x * width,
        y: landmarks[11]?.y * height,
        key: "l_shoulder",
      };
      const r_shoulder = {
        x: landmarks[12]?.x * width,
        y: landmarks[12]?.y * height,
        key: "r_shoulder",
      };
      const l_elbow = {
        x: landmarks[13]?.x * width,
        y: landmarks[13]?.y * height,
        key: "l_elbow",
      };
      const r_elbow = {
        x: landmarks[14]?.x * width,
        y: landmarks[14]?.y * height,
        key: "r_elbow",
      };
      const l_wrist = {
        x: landmarks[15]?.x * width,
        y: landmarks[15]?.y * height,
        key: "l_wrist",
      };
      const r_wrist = {
        x: landmarks[16]?.x * width,
        y: landmarks[16]?.y * height,
        key: "r_wrist",
      };
      const l_hip = {
        x: landmarks[23]?.x * width,
        y: landmarks[23]?.y * height,
        key: "l_hip",
      };
      const r_hip = {
        x: landmarks[24]?.x * width,
        y: landmarks[24]?.y * height,
        key: "r_hip",
      };
      const l_knee = {
        x: landmarks[25]?.x * width,
        y: landmarks[25]?.y * height,
        key: "l_knee",
      };
      const r_knee = {
        x: landmarks[26]?.x * width,
        y: landmarks[26]?.y * height,
        key: "r_knee",
      };
      const l_ankle = {
        x: landmarks[27]?.x * width,
        y: landmarks[27]?.y * height,
        key: "l_ankle",
      };
      const r_ankle = {
        x: landmarks[28]?.x * width,
        y: landmarks[28]?.y * height,
        key: "r_ankle",
      };

      const left_foot_index = {
        x: landmarks[31]?.x * width,
        y: landmarks[31]?.y * height,
        key: "left_heel",
      };
      const right_foot_index = {
        x: landmarks[32]?.x * width,
        y: landmarks[32]?.y * height,
        key: "left_heel",
      };

      // custom
      const neck = {
        x: (l_shoulder.x + r_shoulder.x) / 2,
        y: (l_shoulder.y + l_mouth.y) / 1.8,
        key: "neck",
      };
      const pelvis = {
        x: (l_hip.x + r_hip.x) / 2,
        y: (l_hip.y + l_hip.y) / 2.1,
        key: "pelvis",
      };
      const c_back = {
        x: (l_shoulder.x + r_shoulder.x) / 2,
        y: (neck.y + pelvis.y) / 2.1,
        key: "c_back",
      };

      // draw connectors
      const gradLn = ctx.createLinearGradient(40, 210, 460, 290);
      gradLn.addColorStop(0, "#00B0FF");
      gradLn.addColorStop(1, "#18FFFF");

      ctx.beginPath();
      ctx.moveTo(neck.x, neck.y);
      // ctx.lineTo(forehead.x, forehead.y);

      ctx.moveTo(neck.x, neck.y);
      ctx.lineTo(c_back.x, c_back.y);

      ctx.lineTo(c_back.x, c_back.y);
      ctx.lineTo(pelvis.x, pelvis.y);

      ctx.moveTo(l_shoulder.x, l_shoulder.y);
      ctx.lineTo(neck.x, neck.y);

      ctx.moveTo(neck.x, neck.y);
      ctx.lineTo(r_shoulder.x, r_shoulder.y);

      ctx.moveTo(r_shoulder.x, r_shoulder.y);
      ctx.lineTo(r_elbow.x, r_elbow.y);

      ctx.moveTo(l_shoulder.x, l_shoulder.y);
      ctx.lineTo(l_elbow.x, l_elbow.y);

      ctx.moveTo(l_elbow.x, l_elbow.y);
      ctx.lineTo(l_wrist.x, l_wrist.y);

      ctx.moveTo(r_elbow.x, r_elbow.y);
      ctx.lineTo(r_wrist.x, r_wrist.y);

      ctx.moveTo(l_hip.x, l_hip.y);
      ctx.lineTo(pelvis.x, pelvis.y);

      ctx.moveTo(pelvis.x, pelvis.y);
      ctx.lineTo(r_hip.x, r_hip.y);

      ctx.moveTo(l_hip.x, l_hip.y);
      ctx.lineTo(l_knee.x, l_knee.y);

      ctx.moveTo(r_hip.x, r_hip.y);
      ctx.lineTo(r_knee.x, r_knee.y);

      ctx.moveTo(l_knee.x, l_knee.y);
      ctx.lineTo(l_ankle.x, l_ankle.y);

      ctx.moveTo(r_knee.x, r_knee.y);
      ctx.lineTo(r_ankle.x, r_ankle.y);

      ctx.moveTo(r_ankle.x, r_ankle.y);
      ctx.lineTo(right_foot_index.x, right_foot_index.y);

      ctx.moveTo(l_ankle.x, l_ankle.y);
      ctx.lineTo(left_foot_index.x, left_foot_index.y);

      ctx.lineWidth = 10;
      ctx.strokeStyle = gradLn;
      ctx.lineCap = "round";
      ctx.stroke();
      ctx.closePath();

      // draw points
      [
        neck,
        pelvis,
        c_back,
        l_shoulder,
        r_shoulder,
        l_elbow,
        r_elbow,
        l_wrist,
        r_wrist,
        l_hip,
        r_hip,
        l_knee,
        r_knee,
        l_ankle,
        r_ankle,
        left_foot_index,
        right_foot_index,
      ].forEach((el) => {
        ctx.beginPath();
        ctx.arc(el.x, el.y, 10, 0, 2 * Math.PI, false);
        ctx.lineWidth = 5;
        ctx.strokeStyle = "#FFFFFF";
        ctx.stroke();
        ctx.closePath();
      });

      try {
        let obj:any={
          POSITION:'UN_KNOWN',
          LEFT_HAND_ANGLE:'',
          RIGHT_HAND_ANGLE:'',
          SHOULDER_WIDTH:'',
          RIGHT_KNEE_ANGLE:"",
          LEFT_KNEE_ANGLE:""
        }
        obj.SHOULDER_WIDTH = calculateDistance(landmarks)
        obj.POSITION = getHumanPosition(landmarks)
        obj.LEFT_KNEE_ANGLE = findAngle(landmarks[POSE_LANDMARKS.LEFT_HIP],landmarks[POSE_LANDMARKS_LEFT.LEFT_KNEE],landmarks[POSE_LANDMARKS_LEFT.LEFT_ANKLE])
        obj.RIGHT_KNEE_ANGLE = findAngle(landmarks[POSE_LANDMARKS.RIGHT_HIP],landmarks[POSE_LANDMARKS_RIGHT.RIGHT_KNEE],landmarks[POSE_LANDMARKS_RIGHT.RIGHT_ANKLE])

        obj.RIGHT_HAND_ANGLE = findAngle(landmarks[POSE_LANDMARKS.RIGHT_SHOULDER],landmarks[POSE_LANDMARKS.RIGHT_ELBOW],landmarks[POSE_LANDMARKS.RIGHT_WRIST])
        obj.LEFT_HAND_ANGLE = findAngle(landmarks[POSE_LANDMARKS.LEFT_SHOULDER],landmarks[POSE_LANDMARKS.LEFT_ELBOW],landmarks[POSE_LANDMARKS.LEFT_WRIST])
       
        setParsms(obj)

        ctx.beginPath();
        ctx.arc(landmarks[POSE_LANDMARKS.LEFT_ELBOW].x * width, landmarks[POSE_LANDMARKS.LEFT_ELBOW].y * height, 40, 0, 2 * Math.PI, false);
        ctx.font = "90px Arial";
        ctx.fillStyle = "#FFFFF";
        ctx.fillText(String(Math.round( obj.LEFT_HAND_ANGLE )), landmarks[POSE_LANDMARKS.LEFT_ELBOW].x * width, landmarks[POSE_LANDMARKS.LEFT_ELBOW].y * height - 80,200);
        ctx.lineWidth = 5;
        ctx.strokeStyle = "#FF0000";
        ctx.stroke();
        ctx.rotate(180)
        ctx.closePath();


      } catch (error) {
        // console.error(error);
      }


      ctx.restore();
      // aiResRef.current = {};
    }
  };


  const calculateDistance = (landmarks:any) => {
    const leftShoulder = landmarks[11];
    const rightShoulder = landmarks[12];
    const xDifference = Math.abs(leftShoulder.x - rightShoulder.x);  
    return Math.round(xDifference * 100) +' CM';
  };

  
  const getHumanPosition = (landmarks:any) => {
    let position;
    if (landmarks.length === 0) {
      position = 'unknown';
    } else {
      const headY = landmarks[0].y;
      const neckY = landmarks[11].y;
      const hipY = landmarks[23].y
      const LeftKneeY = landmarks[25].y

      if (headY > neckY && hipY > LeftKneeY) {
        position = 'standing';
      } else {
        position = 'sitting';
      }
 
    }
    return position
  }

  const handleChange = (evt:any) =>{
    evt.preventDefault();
    let file = evt.target.files[0];
    var url = URL.createObjectURL(file);
    processFrames();
    setExternalUrl(url);
  }
 
  const processFrames = () => {
    if ('requestVideoFrameCallback' in HTMLVideoElement.prototype) {
      // The API is supported! 
      videoElementRef.current.requestVideoFrameCallback(async () => {
        await pose.send({ image:  videoElementRef.current });
        await faceMesh.send({ image:  videoElementRef.current });
        processFrames();
      });
    }
  };

  return (
    <div className="App">
      <div className="main">
        <div className={`cam-sec ${externalUrl ? 'external_url' : ''}`}>
          <video
            className="vid"
            ref={videoElementRef}
            src={externalUrl}
            autoPlay
          />
          <canvas className="canvas" ref={canvasRef}></canvas>
        </div>
        <div className="Info">
          <div className="px-4 py-2">
            <button
              type="button"
              className="inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
              data-te-ripple-init
              data-te-ripple-color="light"
              onClick={startPoseModel}
            >
              Start camera
            </button>

            <div className="mt-3 mb-3">
              <div className="flex items-center justify-center w-full">
                <label className="flex flex-col items-center justify-center w-full h-32 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                  <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    <svg
                      aria-hidden="true"
                      className="w-10 h-10 mb-3 text-gray-400"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                      ></path>
                    </svg>
                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                      <span className="font-semibold">Click to upload</span> 
                    </p>
                    <p className="text-xs text-gray-500 dark:text-gray-400">
                     Video format
                    </p>
                  </div>
                  <input id="dropzone-file" type="file" className="hidden" accept="mp4" onChange={handleChange}  />
                </label>
              </div>
            </div>

            <div className="px-4 py-4">
            
            <div className="flex justify-between pb-2 gap-2 items-center">
                <p>DEPTH : </p> <p className="text-sm">{cameraDistance}</p>
              </div>
              <div className="flex justify-between pb-2">
                <p>POSITION : </p> <p>{params.POSITION}</p>
              </div>
              <div className="flex  justify-between pb-2">
                <p>SHOULDER_WIDTH : </p> <p>{params.SHOULDER_WIDTH}</p>
              </div>
              <div className="flex  justify-between pb-2">
                <p>LEFT_HAND_ANGLE : </p> <p>{params.LEFT_HAND_ANGLE}</p>
              </div>
              <div className="flex  justify-between pb-2">
                <p>RIGHT_HAND_ANGLE : </p> <p>{params.RIGHT_HAND_ANGLE}</p>
              </div>
              <div className="flex  justify-between pb-2">
                <p>RIGHT_KNEE_ANGLE : </p> <p>{params.RIGHT_KNEE_ANGLE}</p>
              </div>
              <div className="flex  justify-between ">
                <p>LEFT_KNEE_ANGLE : </p> <p>{params.LEFT_KNEE_ANGLE}</p>
              </div>
            </div>
              <div className="px-4 py-4">
              <a href="/"> Back to Home</a>
              </div>
             
          </div>
        </div>
      </div>
    </div>
  );
}
export default MeasureTool;
