export const findAngle = (a: any, b: any, c: any) => {
    if (a.x === 0 || b.x === 0 || c.x === 0) {
        return 0;
    }
    const radians = Math.atan2(c.y - b.y, c.x - b.x) - Math.atan2(a.y - b.y, a.x - b.x);
    let angle = Math.abs(radians * 180.0 / Math.PI);

    if (angle > 180.0) {
        angle = 360 - angle;
    }

    return Math.round(angle);
}

// Euclidean distance between two points point1, point2 
export const distXY = (point1: any, point2: any) => {
    const diffPoint1 = (point1.x - point2.x) ** 2;
    const diffPoint2 = (point1.y - point2.y) ** 2;
    return (diffPoint1 + diffPoint2) ** 0.5;
}


export const angle_of_singleline = (point1: any, point2: any) => {
    const x_diff = point2.x - point1.x;
    const y_diff = point2.y - point1.y;
    return Math.atan2(y_diff, x_diff) * 180 / Math.PI;
}

export const pointPosition = (point1: any, point2: any, point3: any): string => {
    const value = (point3.x - point2.x) * (point1.y - point2.y) -
        (point3.y - point2.y) * (point1.x - point2.x);
    if (value >= 0) {
        return "left";
    }
    return "right";
}

export const dist_x = (point1: any, point2: any) => {
    return Math.abs(point2.x - point1.x)
}
export const dist_y = (point1: any, point2: any) => {
    return Math.abs(point2.y - point1.y)
}

export const normalize = (value: number, min_val: number, max_val: number) => {
    return (value - min_val) / (max_val - min_val);
}

/**
  * Get highest priority keypoint from points list. 
  * i.e. first index is 1st priority, second index is 2nd priority, and so on.
  */

export const get_available_point = (landmarks: any, points: any) => {
    let avaliablePoint:any = null;
    for(let point of points){
        if(landmarks[point] && avaliablePoint === null){
            avaliablePoint = landmarks[point];
            break;
        }
    }
    return avaliablePoint;
}
