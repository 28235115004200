import { Routes, Route } from "react-router-dom";
import { ErrorBoundary } from "./components/ErrorBoundry";
import Home from "./components/Home";
import NotFound from "./components/PageNotFound";
import MeasureTool from "./components/MeasureTool";
import Squat from "./components/Sqauts";
import Pushup from "./components/Pushup";

const OurFallbackComponent = ({
  error,
  componentStack,
  resetErrorBoundary,
}: any) => (
  <div>
    <h1>
      An error occurred:
      {error.message}
    </h1>
    <h1>
      An error occurred:
      {componentStack}
    </h1>
    <button type="button" onClick={resetErrorBoundary}>
      Try again
    </button>
  </div>
);

const App = () => {
  return (
    <ErrorBoundary FallbackComponent={OurFallbackComponent}>
      <div className="App overflow-hidden bg-[#F4F7FA]">
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Home />
              </>
            }
          />
          <Route
            path="/measuring-tool"
            element={
              <>
                <MeasureTool />
              </>
            }
          />
          <Route
            path="/squat"
            element={
              <>
                <Squat />
              </>
            }
          />

          <Route
            path="/pushup"
            element={
              <>
                <Pushup />
              </>
            }
          />

          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </ErrorBoundary>
  );
};
export default App;
