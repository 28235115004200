import { useRef, useCallback, useEffect, useState } from "react";
import { Camera } from "@mediapipe/camera_utils";
import { Pose, POSE_LANDMARKS, POSE_LANDMARKS_LEFT } from "@mediapipe/pose";
import { POSE_LANDMARKS_RIGHT } from "@mediapipe/pose";
import { dist_y, findAngle, get_available_point, pointPosition } from "../Utils/PoseAlgorithms";

declare global {
  interface Window {
    stream?: any;
  }
}
function Pushup() {
  let pose: any;
  let stage: any;
  const [repCounter, setRepCounter] = useState(0);
  const [externalUrl, setExternalUrl] = useState('');

  let down: any;
  const videoElementRef = useRef<any>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const medpipeURL = "https://cdn.jsdelivr.net/npm/@mediapipe/pose";
  const poseOptions = {
    modelComplexity: 2,
    smoothLandmarks: true,
    enableSegmentation: true,
    smoothSegmentation: true,
    minDetectionConfidence: 0.5,
    minTrackingConfidence: 0.2,
  };
  useEffect(() => {
    if (pose) return;

    pose = new Pose({
      locateFile: (file) => {
        return `${medpipeURL}/${file}`;
      },
    });

    pose.setOptions(poseOptions);
    pose.onResults(resultsCallback);

    console.log("Model loaded");
  }, []);

  const resultsCallback = useCallback((results: any) => {
    const landmarks = results.poseLandmarks ?? {};
    try {
      // * we get errors every time the landmarks are not available
      if (Object.keys(landmarks).length) {
        drawLandmarksHandler(landmarks);
      }
    } catch (error) {
      // console.error(error);
    }

   
  }, []);

  useEffect(() => {
    // if (videoElementRef) startPoseModel();

    return () => {
      stopCamera();
    };
  }, [videoElementRef, resultsCallback]);

  const startPoseModel = useCallback(() => {
    if (videoElementRef.current && pose) {
      const camera = new Camera(videoElementRef.current, {
        onFrame: async () => {
          await pose?.send({ image: videoElementRef.current });
        },
        width: 640,
        height: 480,
      });
      camera.start();
    }
  }, [pose]);

  const stopCamera = () => {
    window?.stream?.getTracks()?.forEach((track: any) => track.stop());

    if (videoElementRef.current === null) {
      return;
    }

    const stream = videoElementRef.current.srcObject as MediaStream;
    stream?.getTracks()?.forEach((track) => track.stop());
    videoElementRef.current.srcObject = null;
  };

  const drawLandmarksHandler = (landmarks: any) => {
    // no need to draw anything
    if (!canvasRef || !canvasRef.current) {
      return;
    }
    // canvasRef.current.width = videoElementRef.current.video.videoWidth
    // canvasRef.current.height = videoElementRef.current.video.videoHeight

    const canvasEl = canvasRef.current;
    const ctx = canvasEl?.getContext("2d");

    if (canvasEl && ctx) {
      canvasEl.height = canvasEl.clientHeight;
      canvasEl.width = canvasEl.clientWidth;

      ctx.save();
      ctx.clearRect(0, 0, canvasEl.width, canvasEl.height);

      const width = canvasEl.width;
      const height = canvasEl.height;
      poseEstimation(landmarks,width,height);

      // calc x/y
      const l_eye = {
        x: landmarks[2]?.x * width,
        y: landmarks[2]?.y * height,
        key: "l_eye",
      };
      const r_eye = {
        x: landmarks[5]?.x * width,
        y: landmarks[5]?.y * height,
        key: "r_eye",
      };
      const l_mouth = {
        x: landmarks[9]?.x * width,
        y: landmarks[9]?.y * height,
        key: "l_mouth",
      };
      const l_shoulder = {
        x: landmarks[11]?.x * width,
        y: landmarks[11]?.y * height,
        key: "l_shoulder",
      };
      const r_shoulder = {
        x: landmarks[12]?.x * width,
        y: landmarks[12]?.y * height,
        key: "r_shoulder",
      };
      const l_elbow = {
        x: landmarks[13]?.x * width,
        y: landmarks[13]?.y * height,
        key: "l_elbow",
      };
      const r_elbow = {
        x: landmarks[14]?.x * width,
        y: landmarks[14]?.y * height,
        key: "r_elbow",
      };
      const l_wrist = {
        x: landmarks[15]?.x * width,
        y: landmarks[15]?.y * height,
        key: "l_wrist",
      };
      const r_wrist = {
        x: landmarks[16]?.x * width,
        y: landmarks[16]?.y * height,
        key: "r_wrist",
      };
      const l_hip = {
        x: landmarks[23]?.x * width,
        y: landmarks[23]?.y * height,
        key: "l_hip",
      };
      const r_hip = {
        x: landmarks[24]?.x * width,
        y: landmarks[24]?.y * height,
        key: "r_hip",
      };
      const l_knee = {
        x: landmarks[25]?.x * width,
        y: landmarks[25]?.y * height,
        key: "l_knee",
      };
      const r_knee = {
        x: landmarks[26]?.x * width,
        y: landmarks[26]?.y * height,
        key: "r_knee",
      };
      const l_ankle = {
        x: landmarks[27]?.x * width,
        y: landmarks[27]?.y * height,
        key: "l_ankle",
      };
      const r_ankle = {
        x: landmarks[28]?.x * width,
        y: landmarks[28]?.y * height,
        key: "r_ankle",
      };

      // custom
      const neck = {
        x: (l_shoulder.x + r_shoulder.x) / 2,
        y: (l_shoulder.y + l_mouth.y) / 1.8,
        key: "neck",
      };
      const pelvis = {
        x: (l_hip.x + r_hip.x) / 2,
        y: (l_hip.y + l_hip.y) / 2.1,
        key: "pelvis",
      };
      const c_back = {
        x: (l_shoulder.x + r_shoulder.x) / 2,
        y: (neck.y + pelvis.y) / 2.1,
        key: "c_back",
      };

      // draw connectors
      const gradLn = ctx.createLinearGradient(40, 210, 460, 290);
      gradLn.addColorStop(0, "#00B0FF");
      gradLn.addColorStop(1, "#18FFFF");

      ctx.beginPath();
      ctx.moveTo(neck.x, neck.y);
      // ctx.lineTo(forehead.x, forehead.y);

      ctx.moveTo(neck.x, neck.y);
      ctx.lineTo(c_back.x, c_back.y);

      ctx.lineTo(c_back.x, c_back.y);
      ctx.lineTo(pelvis.x, pelvis.y);

      ctx.moveTo(l_shoulder.x, l_shoulder.y);
      ctx.lineTo(neck.x, neck.y);

      ctx.moveTo(neck.x, neck.y);
      ctx.lineTo(r_shoulder.x, r_shoulder.y);

      ctx.moveTo(r_shoulder.x, r_shoulder.y);
      ctx.lineTo(r_elbow.x, r_elbow.y);

      ctx.moveTo(l_shoulder.x, l_shoulder.y);
      ctx.lineTo(l_elbow.x, l_elbow.y);

      ctx.moveTo(l_elbow.x, l_elbow.y);
      ctx.lineTo(l_wrist.x, l_wrist.y);

      ctx.moveTo(r_elbow.x, r_elbow.y);
      ctx.lineTo(r_wrist.x, r_wrist.y);

      ctx.moveTo(l_hip.x, l_hip.y);
      ctx.lineTo(pelvis.x, pelvis.y);

      ctx.moveTo(pelvis.x, pelvis.y);
      ctx.lineTo(r_hip.x, r_hip.y);

      ctx.moveTo(l_hip.x, l_hip.y);
      ctx.lineTo(l_knee.x, l_knee.y);

      ctx.moveTo(r_hip.x, r_hip.y);
      ctx.lineTo(r_knee.x, r_knee.y);

      ctx.moveTo(l_knee.x, l_knee.y);
      ctx.lineTo(l_ankle.x, l_ankle.y);

      ctx.moveTo(r_knee.x, r_knee.y);
      ctx.lineTo(r_ankle.x, r_ankle.y);

      ctx.lineWidth = 10;
      ctx.strokeStyle = gradLn;
      ctx.lineCap = "round";
      ctx.stroke();
      ctx.closePath();



      // draw points
      [
        neck,
        pelvis,
        c_back,
        l_shoulder,
        r_shoulder,
        l_elbow,
        r_elbow,
        l_wrist,
        r_wrist,
        l_hip,
        r_hip,
        l_knee,
        r_knee,
        l_ankle,
        r_ankle,
      ].forEach((el) => {
        ctx.beginPath();
        ctx.arc(el.x, el.y, 10, 0, 2 * Math.PI, false);
        ctx.lineWidth = 5;
        ctx.strokeStyle = "#FFFFFF";
        ctx.stroke();
        ctx.closePath();
      });

      ctx.restore();
      // aiResRef.current = {};
    }
  };

  const poseEstimation = (landmarks: any,width:number,height:number) => {

    const head_point = get_available_point(landmarks,[POSE_LANDMARKS.NOSE, POSE_LANDMARKS.LEFT_EAR, POSE_LANDMARKS.RIGHT_EAR, POSE_LANDMARKS.LEFT_EYE, POSE_LANDMARKS.RIGHT_EYE])
    const ankle = get_available_point(landmarks,[POSE_LANDMARKS_LEFT.LEFT_ANKLE,POSE_LANDMARKS_RIGHT.RIGHT_ANKLE])
    if(!head_point || !ankle ){
      return
    }

    const diff_y = dist_y(head_point, ankle)
    const point2 = {
      x:width / 2,
      y:0
    }
    const point3 ={
      x:width / 2,
      y:height / 2
    }
   const  head_pos = pointPosition(head_point,point2, point3)
   const  wrist = get_available_point(landmarks,[POSE_LANDMARKS_LEFT.LEFT_WRIST, POSE_LANDMARKS_RIGHT.RIGHT_WRIST])
   const  ang = findAngle(head_point, ankle, wrist)
   if(diff_y < 250 && (ang <40 &&  head_pos == "right") || (ang > 140 && head_pos == "left")){
      stage = "up";
   }

   if((diff_y * 100) > 20 && stage === "up"){
    setRepCounter((count) => count + 1);
        stage = "down";
   }
  };

  const handleChange = (evt:any) =>{
    evt.preventDefault();
    let file = evt.target.files[0];
    var url = URL.createObjectURL(file);
    processFrames();
    setExternalUrl(url);
  }

  const processFrames = () => {
    if ('requestVideoFrameCallback' in HTMLVideoElement.prototype) {
      // The API is supported! 
      videoElementRef.current.requestVideoFrameCallback(async () => {
        await pose.send({ image:  videoElementRef.current });
        processFrames();
      });
    }
  };



  return (
    <div className="App">
      <div className="main">
        <div className={`cam-sec ${externalUrl ? 'external_url' : ''}`}>
          <video
            className="vid"
            ref={videoElementRef}
            src={externalUrl}
            autoPlay
          />
          <canvas className="canvas" ref={canvasRef}></canvas>
        </div>
        <div className="Info">
          <div className="px-4 py-2">
            <button
              type="button"
              className="inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
              data-te-ripple-init
              data-te-ripple-color="light"
              onClick={startPoseModel}
            >
              Start camera
            </button>

            <div className="mt-3 mb-3">
              <div className="flex items-center justify-center w-full">
                <label className="flex flex-col items-center justify-center w-full h-32 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                  <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    <svg
                      aria-hidden="true"
                      className="w-10 h-10 mb-3 text-gray-400"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                      ></path>
                    </svg>
                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                      <span className="font-semibold">Click to upload</span> 
                    </p>
                    <p className="text-xs text-gray-500 dark:text-gray-400">
                     Video format
                    </p>
                  </div>
                  <input id="dropzone-file" type="file" className="hidden" accept="mp4" onChange={handleChange}  />
                </label>
              </div>
            </div>

            <div className="px-4 py-4">
              <div className="flex justify-between pb-2">
                <p>PUSHUP COUNT : {repCounter}</p> <p></p>
              </div>
            </div>
              <div className="px-4 py-4">
              <a href="/"> Back to Home</a>
              </div>
             
          </div>
        </div>
      </div>
    </div>
  );
}
export default Pushup;
