import { Link } from "react-router-dom";
const list = [{
  name:'Measuring Tool',
  description:'Depth measuring tool with MediaPipe and calcuate distanse between each body landmarks and angles',
  link:'measuring-tool'
},
{
name:'Squat',
description:'We focus on Head, Hand, Foot, Hip, and Knee landmarks (key points) in the human body. to determine the Squat Exercise',
link:'squat'
}, {
  name:'Pushup',
  description:'To predict the exercise performed in the video is pushup, we focus on Shoulder, Hip, and Ankle landmarks (key points) in the human body',
  link:'pushup'
}
]
const Home = () => {
  return (
    <main>
      <header>
        <nav className="z-10 w-full absolute">
          <div className="container">
            <div className="flex flex-wrap items-center justify-between py-2 gap-6 md:py-4 md:gap-0 relative">
              <input
                aria-hidden="true"
                type="checkbox"
                name="toggle_nav"
                id="toggle_nav"
                className="hidden peer"
              />
              <div className="relative z-20 w-full flex justify-between lg:w-max md:px-0">
                <Link
                  aria-label="logo"
                  to={"/"}
                  className="flex space-x-2 items-center"
                >
                  <span className="text-2xl font-bold text-gray-900 dark:text-white">
                    MOTION TECHNOLOGY
                  </span>
                </Link>
                <div className="relative flex items-center lg:hidden max-h-10">
                  <label
                    role="button"
                    aria-label="humburger"
                    id="hamburger"
                    className="relative  p-6 -mr-6"
                  >
                    <div
                      aria-hidden="true"
                      id="line"
                      className="m-auto h-0.5 w-5 rounded bg-sky-900 dark:bg-gray-300 transition duration-300"
                    ></div>
                    <div
                      aria-hidden="true"
                      id="line2"
                      className="m-auto mt-2 h-0.5 w-5 rounded bg-sky-900 dark:bg-gray-300 transition duration-300"
                    ></div>
                  </label>
                </div>
              </div>

              <div
                aria-hidden="true"
                className="fixed z-10 inset-0 h-screen w-screen bg-white/70 backdrop-blur-2xl origin-bottom scale-y-0 transition duration-500 peer-checked:origin-top peer-checked:scale-y-100 lg:hidden dark:bg-gray-900/70"
              ></div>
              <div
                className="flex-col z-20 flex-wrap gap-6 p-8 rounded-3xl border border-gray-100 bg-white shadow-2xl shadow-gray-600/10 justify-end w-full invisible opacity-0 translate-y-1  absolute top-full left-0 transition-all duration-300 scale-95 origin-top 
                            lg:relative lg:scale-100 lg:peer-checked:translate-y-0 lg:translate-y-0 lg:flex lg:flex-row lg:items-center lg:gap-0 lg:p-0 lg:bg-transparent lg:w-7/12 lg:visible lg:opacity-100 lg:border-none
                            peer-checked:scale-100 peer-checked:opacity-100 peer-checked:visible lg:shadow-none 
                            dark:shadow-none dark:bg-gray-800 dark:border-gray-700"
              >
                <div className="text-gray-600 dark:text-gray-300 lg:pr-4 lg:w-auto w-full lg:pt-0">
                  <ul className="tracking-wide font-medium lg:text-sm flex-col flex lg:flex-row gap-6 lg:gap-0">
                    <li>
                      <Link
                        to={"/"}
                        className="block md:px-4 transition hover:text-primary"
                      >
                        <span>Exercises</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={"/"}
                        className="block md:px-4 transition hover:text-primary"
                      >
                        <span>How it works</span>
                      </Link>
                    </li>
                  </ul>
                </div>

                <div className="mt-12 lg:mt-0">
                  <Link
                    to={"/squat"}
                    className="relative flex h-9 w-full items-center justify-center px-4 before:absolute before:inset-0 before:rounded-full before:bg-primary before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 sm:w-max"
                  >
                    <span className="relative text-sm font-semibold text-white">
                      Explore Model
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>

      <div className="relative" id="home">
        <div
          aria-hidden="true"
          className="absolute inset-0 grid grid-cols-2 -space-x-52 opacity-40 dark:opacity-20"
        ></div>
        <div className="container">
          <div className="relative pt-28 ml-auto">
            <div className="w-full text-center mx-auto">
              <h1 className="text-gray-900 w-full md:w-2/3 m-auto dark:text-white font-bold text-5xl md:text-6xl xl:text-7xl">
                Unlock Your Potential{" "}
                <span className="text-primary dark:text-white">With AI</span>
              </h1>
              <p className="mt-8 text-gray-700 dark:text-gray-300">
                AI-powered pose estimation is a rapidly growing field of
                computer vision technology that enables machines to accurately
                detect and track human body poses in real-time. This technology
                has a wide range of applications, from virtual reality and
                augmented reality to robotics and motion capture. With
                AI-powered pose estimation, machines can accurately detect and
                track human body poses in real-time, allowing for more accurate
                and efficient motion capture.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div id="features">
        <div className="container">
          <div className="md:w-2/3 lg:w-1/2">
            <h2 className="my-8 text-2xl font-bold text-gray-700 dark:text-white md:text-4xl">
              Our Solutions
            </h2>
           
          </div>
          <div className="my-16 grid gap-2  overflow-hidden   border-gray-100 text-gray-600 dark:border-gray-700 sm:grid-cols-2 lg:grid-cols-4 lg:divide-y-0 xl:grid-cols-4">
           
             {
              list.map(x=>
                <div className="group relative bg-white dark:bg-gray-800 transition hover:z-[1] hover:shadow-2xl hover:shadow-gray-600/10">
                <div className="relative space-y-8 py-12 p-8">
                  <div className="space-y-2">
                    <h5 className="text-xl font-semibold text-gray-700 dark:text-white transition group-hover:text-secondary">
                     {x.name}
                    </h5>
                    <p className="text-gray-600 dark:text-gray-300">
                      {x.description}
                    </p>
                  </div>
                  <a
                    href={x.link}
                    className="flex items-center justify-between group-hover:text-secondary"
                  >
                    <span className="text-sm">Explore</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="w-5 h-5 -translate-x-4 text-2xl opacity-0 transition duration-300 group-hover:translate-x-0 group-hover:opacity-100"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M12.97 3.97a.75.75 0 011.06 0l7.5 7.5a.75.75 0 010 1.06l-7.5 7.5a.75.75 0 11-1.06-1.06l6.22-6.22H3a.75.75 0 010-1.5h16.19l-6.22-6.22a.75.75 0 010-1.06z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </a>
                </div>
              </div>
                )
             }
          </div>
        </div>
      </div>

    </main>
  );
};

export default Home;
